import React,{useState} from 'react'
import './Styles.css'


const App = () => {

   const [inputList, setInputList] = useState("Buy Apple");
   const [items,setItems] =useState([]);

   const itemEvents=(event) =>{
          
        setInputList(event.target.value);
   }
      
   

   const listOfItems =() =>{
       
         setItems((oldItems) =>{
          return [...oldItems,inputList]
         });
         setInputList("");
       
   }
   const deleteItems =(id) =>{
    console.log("Clicked") 
    setItems ((oldItems) =>{
      return oldItems.filter((arrElement,index)=>{
         return index !==id
      })
    })
 }

  return (
    <>
      <div className='main_div'>
          <div className='center_div'>
            <br />
            <h1>ToDo List</h1>
            <br />
            <input type="text" placeholder='Add a item' onChange={itemEvents} value={inputList} />
            <button onClick={listOfItems}> + </button>
            
          </div>

      </div>
    </>
  )
}
export default App;